<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true}"></v-header>
    </div>
    <div class="info">
        <p>积分情况</p>
        <p>
            <span>太阳☀️:{{ sunNum }}</span>
            <span>月亮🌛:{{ moonNum }}</span>
            <span>星星⭐️:{{ startNum }}</span>
        </p>
    </div>
    <div class="buttons">
        <p>获取积分方式：</p>
        <button @click="recharge">充值</button>
        <button @click="becomesvip">成为SVIP享更多服务</button>
        <button @click="share">分享</button>
        <button @click="signin">签到</button>
        <button @click="publish">发预测</button>

    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
export default {
    components:{
        vHeader
    },
    data(){
        return{
            title:this.$route.meta.title,
            sunNum:0.0,
            moonNum:0.0,
            startNum:0.0,

        }
    },
    methods:{
        recharge(){
            this.$router.push({
                                name:'paymenth5',
                                query:{
                                    userid:this.$store.state.common.loginInfo.userId,
                                    access_token:this.$store.state.common.loginInfo.access_token,
                                    
                                }
            })
        },
        becomesvip(){
            this.$router.push({
                                name:'vippayment',
                                query:{
                                    userid:this.$store.state.common.loginInfo.userId,
                                    access_token:this.$store.state.common.loginInfo.access_token,
                                    
                                }
            })

        },
        share(){
            this.$toast('敬请期待，开发中')
        },
        signin(){
            this.$router.push({
                name:'attendance'
            })
        },
        publish(){
            this.$toast('敬请期待，开发中')
        },
        async getFansBalance(){
            let data = {
                userid: this.$store.state.common.loginInfo.userId,
                system_id: 100001, 
                }
            const res = await this.$HTTP.get(this, this.$API['API_FANS_BALANCE'], data, {headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    // console.log('粉丝的账户余额',res)
                                    this.sunNum=res.data.sunPoint
                                    this.moonNum=parseFloat(res.data.donatedMoon)+parseFloat(res.data.moonPoint)
                                    this.startNum=parseFloat(res.data.donatedStar)+parseFloat(res.data.starPoint)
                                }
        }
    },
    mounted(){
        if (this.$store.state.common.loginInfo.access_token) {
            this.getFansBalance()
        } else {
            this.$router.replace('/login')
        }
    },

}
</script>

<style lang="less" scoped>
*{
    margin: 0;
    padding: 0;
}
p{
    text-align: center;

    font-size: 0.4rem;
}
.info{
    p{
        margin: 0.2rem;
        span{
            margin: 0 0.3rem;
        }
    }
}
.buttons{
    margin: 1rem 0 0 0;
    p{
        color: red;
    }
    button{
        border-radius: 5px;
        margin: 0.1rem 2rem;
        width: 6rem;
        height: 1.5rem;
        font-size: 0.4rem;
        text-align: center;
        display: block;
        border: 0;
        color: white;
        background-color: red;
    }
}
</style>